import redirect from "./Redirect"

const handleReset = (navigate) => {
    localStorage.removeItem("step3")
    localStorage.removeItem("step1")
    localStorage.removeItem("step2")
    localStorage.removeItem("food-pairing")
    localStorage.removeItem("region")
    localStorage.removeItem("grape_variety")
    localStorage.removeItem("occasion")
    localStorage.removeItem("red") 
    localStorage.removeItem("white")
    localStorage.removeItem("rose")
    localStorage.removeItem("sparkling")
    localStorage.removeItem("Fortified")
    sessionStorage.removeItem("step3")
    sessionStorage.removeItem("step1")
    sessionStorage.removeItem("step2")
    sessionStorage.removeItem("food-pairing")
    sessionStorage.removeItem("region")
    sessionStorage.removeItem("grape_variety")
    sessionStorage.removeItem("occasion")
    sessionStorage.removeItem("red") 
    sessionStorage.removeItem("white")
    sessionStorage.removeItem("rose")
    sessionStorage.removeItem("sparkling")
    sessionStorage.removeItem("Fortified")
    redirect(navigate)
}
export default handleReset;