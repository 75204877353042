import * as React from 'react';
import './title.css';


const Title = () => {
  return (
    <div>
        <div className='text'>{"Discover the wine you really want"}</div>

    </div>
  )
}

export default Title