import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect } from "react";
import Title from "../titleview/Title";
import { useNavigate } from "react-router-dom";
import svgicon1 from "../../../asset/component/step2/bottle1step2.svg";
import svgicon2 from "../../../asset/component/step2/bottle2step2.svg";
import svgicon3 from "../../../asset/component/step3/bottle3step3.svg";
import svgicon4 from "../../../asset/component/step4/bottle4step4.svg";
import redirect from "../../Redirect";
import axios from "axios";
import URL from "../../../config/baseURL";
import handleback from "../../handleback";
import handlebottle from "../../handleBottle";

const By_ocassion = () => {
  const [checked, setChecked] = React.useState("");
  const [occasiondata, setoccasiondata] = React.useState([]);
  useEffect(() => {
    axios
      .get(`${URL}/get-occasion`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setoccasiondata(res.data.occasions);
      
      })
      .catch((err) => {
        if (err) {
          localStorage.clear()
          redirect(navigate)
        }
      })
  }, []);

 
  const handleChange = (event) => {
    setChecked(event.target.value);
  };
  const navigate = useNavigate();
  useEffect(() => {
    redirect(navigate)
  }, [])

  const handlesubmitanswer = (e) => {
    localStorage.setItem("occasion", checked)
    axios
      .post(
        `${URL}/submit-answersv2`,
        {
          "price": JSON.parse(localStorage.getItem("step1")),
          "wine_type": JSON.parse(localStorage.getItem("step2")),
          "filter_by": localStorage.getItem("step3"),
          "food": [],
          "region": [],
          "occasion": localStorage.getItem("occasion"),
          "grape_variety": [],
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        if (res.data.code === 200) {
          navigate("/result");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  return (
    <div className="position-relative">
        <Button className="button back-button mx-2 " onClick={() => handleback(navigate,"step3")} variant="contained">
              back
            </Button>
      <Box className="card-step-section">
        <Card className="card-step-wrapper" variant="outlined">
          <Title />
          <div className="icons-wrapper">
          <img className="iconimage" src={svgicon1} alt="" srcSet=""  onClick={() => handlebottle(navigate,"step1")} />
            <img className="iconimage" src={svgicon2} alt="" srcSet=""  onClick={() => handlebottle(navigate,"step2")} />
            <img className="iconimage" src={svgicon3} alt="" srcSet=""  onClick={() => handlebottle(navigate,"step3")}  />
            <img className="iconimage" src={svgicon4} alt="" srcSet="" />
          </div>

          <div className="question">
            <p className="questep2">
              What is your occasion?
            </p>
          </div>
          <div className="select-options select-radio">
            <RadioGroup
              onChange={(e) => (handleChange(e))}
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="wine"
              name="radio-buttons-group"
            >
               {occasiondata.length === 0 ?
              <div className='loader small-custom-loader'>
                <CircularProgress sx={{ color: "#720e37" }} />
              </div>
              :
              
                occasiondata.map((value) => (
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    value={value.name}
                    label={value.name}
                    name="best_wine_matches"
                  />
                ))
              
              }
            </RadioGroup>
          </div>

          <Grid
            className="submit-answer-wrapper"
            item
            container
            justify="center"
            md={12}

          >
            <Button
              className="submit-answer"
              variant="contained"
              type="submit"
              onClick={(e) => handlesubmitanswer(e)}
              size="large"
              fullWidth
            >
              <span className="submit"> Submit Answer </span>
            </Button>
          </Grid>
        </Card>
      </Box>
    </div>
  );
};

export default By_ocassion;
