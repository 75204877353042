import { Box, Button, Card, Chip, CircularProgress, Grid } from "@mui/material";
import React, { useEffect } from "react";
import Title from "../titleview/Title";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import svgicon1 from "../../../asset/component/step2/bottle1step2.svg";
import svgicon2 from "../../../asset/component/step2/bottle2step2.svg";
import svgicon3 from "../../../asset/component/step3/bottle3step3.svg";
import svgicon4 from "../../../asset/component/step4/bottle4step4.svg";
import redirect from "../../Redirect";
import URL from "../../../config/baseURL";
import axios from "axios";
import handleback from "../../handleback";
import Regiondropdown from "./regiondropdown";
import handlebottle from "../../handleBottle";

const By_region = (props) => {
  const [RegionsDataAPI, setRegionsDataAPI] = React.useState([]);
  const [SelectedRegion, setSelectedRegion] = React.useState([]);
  const [PopularRegions, setPopularRegions] = React.useState([]);
  const [mySet,setmySet] = React.useState([])
  const RegionName = [];


  useEffect(() => {
    axios
      .get(`${URL}/get-regions`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setRegionsDataAPI(res.data.regions);
        setPopularRegions(res.data.popular);
      })
      .catch((err) => {
        if (err) {
          localStorage.clear();
          redirect(navigate);
        }
      });
  }, []);

  RegionsDataAPI.map((region) => {
    RegionName.push(region.name);
    RegionName.push(region.sub_regions.name);
  });

  const PopularRegion = [];
  PopularRegions.map((region) => {
    PopularRegion.push(region.name);
  });
  const navigate = useNavigate();
  useEffect(() => {
    redirect(navigate);
  }, []);

  const handlesubmitanswer = () => {
    if (SelectedRegion.length !== 0 || mySet.length !== 0) {
      localStorage.setItem(
        "region",
        JSON.stringify(mySet.concat(SelectedRegion))
      );
      axios
        .post(
          `${URL}/submit-answersv2`,
          {
            price: JSON.parse(localStorage.getItem("step1")),
            wine_type: JSON.parse(localStorage.getItem("step2")),
            filter_by: localStorage.getItem("step3"),
            food: [],
            region: JSON.parse(localStorage.getItem("region")),
            occasion: "",
            grape_variety: [],
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.code === 200) {
            navigate("/result");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div className="position-relative">
      <Button
        className="button back-button mx-2"
        onClick={() => handleback(navigate, "step3")}
        variant="contained"
      >
        back
      </Button>
      <Box className="card-step-section">
        <Card className="card-step-wrapper" variant="outlined">
          <Title />
          <div className="icons-wrapper">
            <img
              className="iconimage"
              src={svgicon1}
              alt=""
              srcSet=""
              onClick={() => handlebottle(navigate, "step1")}
            />
            <img
              className="iconimage"
              src={svgicon2}
              alt=""
              srcSet=""
              onClick={() => handlebottle(navigate, "step2")}
            />
            <img
              className="iconimage"
              src={svgicon3}
              alt=""
              srcSet=""
              onClick={() => handlebottle(navigate, "step3")}
            />
            <img className="iconimage" src={svgicon4} alt="" srcSet="" />
          </div>
          <div className="question">
            <p className="questep2">
              What region are you’re looking to discover wines from?
            </p>
          </div>
          <div className="ticket-select-dropdown">
            <FormControl>
              <Regiondropdown
                
                mySet={mySet}
                setmySet={setmySet}
                data={RegionsDataAPI}
                select={SelectedRegion}
                manage={setSelectedRegion}
              />
            </FormControl>
          </div>
          <div className="popular drop-down-cards">
            {PopularRegion.length === 0 ? (
              <div className="loader small-custom-loader">
                <CircularProgress sx={{ color: "#720e37" }} />
              </div>
            ) : (
              PopularRegion.map((value, index) =>
                SelectedRegion?.includes(value) ? (
                  <Button
                    key={index}
                    className="col-5 submit-answer disabled"
                    variant="contained"
                    size="large"
                  >
                    {value}
                  </Button>
                ) : (
                  <Button
                    key={index}
                    className="col-5 submit-answer"
                    variant="contained"
                    onClick={async () => {
                      setSelectedRegion([...SelectedRegion, value]);
                    }}
                    size="large"
                  >
                    {value}
                  </Button>
                )
              )
            )}
          </div>

          <Grid
            className="submit-answer-wrapper"
            item
            container
            justify="center"
            md={12}
          >
            <Button
              className="submitanswerstep4"
              variant="contained"
              type="submit"
              onClick={(e) => handlesubmitanswer(e)}
              size="large"
              fullWidth
            >
              <span className="submit"> Submit Answer </span>
            </Button>
          </Grid>
        </Card>
      </Box>
    </div>
  );
};

export default By_region;
