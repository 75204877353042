import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect } from "react";
import Title from "../titleview/Title";
import { useNavigate } from "react-router-dom";
import svgicon1 from "../../../asset/component/step2/bottle1step2.svg";
import svgicon2 from "../../../asset/component/step2/bottle2step2.svg";
import svgicon3 from "../../../asset/component/step3/bottle3step3.svg";
import redirect from "../../Redirect";
import handleback from "../../handleback";
import handlebottle from "../../handleBottle";


const Step3 = () => {
  const navigate = useNavigate();
  useEffect(() => {
    redirect(navigate);
  }, []);

  const [checked, setChecked] = React.useState("");
  const handleChange = (event) => {
    setChecked(event.target.value);
  };
  const handlesubmitanswer = () => {
    if (checked === "region") {
      localStorage.setItem("step3", "region") 
      sessionStorage.setItem("step3", "region");
      navigate("/step-3/region");
    } else if (checked === "occasion") {
      localStorage.setItem("step3", "occasion") 
      sessionStorage.setItem("step3", "occasion");
      navigate("/step-3/occasion");
    } else if (checked === "food") {
      localStorage.setItem("step3", "food") 
      sessionStorage.setItem("step3", "food");
      navigate("/step-3/food");
    } else if (checked === "grape") {
      localStorage.setItem("step3", "grape") 
      sessionStorage.setItem("step3", "grape");
      navigate("/step-3/grape");
    }
    else if(checked === ""){
      localStorage.setItem("step3" , sessionStorage.getItem("step3"));
      if (sessionStorage.getItem("step3") === "region") {
        navigate("/step-3/region");
      } else if (sessionStorage.getItem("step3") === "occasion") {
        navigate("/step-3/occasion");
      } else if (sessionStorage.getItem("step3") === "food") {
        navigate("/step-3/food");
      } else if (sessionStorage.getItem("step3") === "grape") {
        navigate("/step-3/grape");
      }
      
    }
  };
  const winearr = [
    {
      name: "By Food Pairing",
      value:"food"
    },
    {
      name: "By Region",
      value:"region"
    },
    {
      name: "By Grape Varietal",
      value: "grape"
    },
    {
      name: "By Occasion",
      value: "occasion"
    }
  ]
  
  const step3 = sessionStorage.getItem("step3")
 
  return (
    <div className="position-relative">
        <Button className="button back-button mx-2" onClick={() => handleback(navigate,"step2")} variant="contained">
              back
            </Button>
      <Box className="card-step-section">
        <Card className="card-step-wrapper" variant="outlined">
          <Title />
          <div className=" icons-wrapper">
            <img className="iconimage" src={svgicon1} alt="" srcSet=""  onClick={() => handlebottle(navigate,"step1")} />
            <img className="iconimage" src={svgicon2} alt="" srcSet="" onClick={() => handlebottle(navigate,"step2")}  />
            <img className="iconimage" src={svgicon3} alt="" srcSet="" />
          </div>

          <div className="question">
            <p className="questep2">
              How do you want to discover the best wine matches for you?
            </p>
          </div>
          <div className=" select-options select-radio">
            <RadioGroup
              onChange={(e) => handleChange(e)}
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={step3}
              name="radio-buttons-group"
            >
              {
                winearr.map((value) =>(
                  step3 === value.value ?
                  <FormControlLabel
                control={<Radio color="primary" />}
                value={value.value}
                label={value.name}
                name="best_wine_matches"
              />:
              <FormControlLabel
              control={<Radio color="primary" />}
              value={value.value}
              label={value.name}
              name="best_wine_matches"
            />
              ))
              }
             
            </RadioGroup>
          </div>

          <Grid
            className="submit-answer-wrapper"
            item
            container
            justify="center"
            md={12}
          >
            <Button
              className="submit-answer"
              variant="contained"
              type="submit"
              onClick={(e) => handlesubmitanswer(e)}
              size="large"
              fullWidth
            >
              <span className="submit"> Submit Answer </span>
            </Button>
          </Grid>
        </Card>
      </Box>
    </div>
  );
};

export default Step3;
