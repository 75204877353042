import React, { useEffect, useState } from "react";
import logotitle from "../../asset/image/logowithname.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./loginform.css";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import redirect from "../Redirect";
import axios from 'axios';
import URL from "../../config/baseURL";
import handleReset from "../handlereset";

const Loginform = () => {
  const [user, setuser] = useState({ name: "", email: "" });
  const [errorState, seterrorState] = useState({
    nameErr: false,
    emailErr: false,
  });
  const navigate = useNavigate();
  useEffect(() => {
    redirect(navigate);
  }, []);
  
  let formData = new FormData();   
formData.append('first_name', user.name); 
formData.append('email', user.email);

const handleLogin = () =>{
  axios.post(`${URL}/login`, formData)
            .then(response => {
                const { token } = response.data;
                localStorage.setItem('token', token)
                sessionStorage.setItem('token', token)
                navigate("/step-1");
            })
            .catch(err => {
                if(err) { console.log(err) }
            })
    }

  window.onbeforeunload = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    handleReset(navigate)
     navigate("/");
  }
  const handleError = (e) => {
    if (user.name === "") {
      seterrorState({ nameErr: true, emailErr: false });
    } else if (user.email === "") {
      seterrorState({ nameErr: false, emailErr: true });
    } else {
      seterrorState({ nameErr: false, emailErr: false });
    }
  };
 
  const validation = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
  const handleSubmit = () => {
    if (user.name === "" && user.email === "") {
      seterrorState({ nameErr: true, emailErr: true });
    } else if (user.name === "") {
      seterrorState({ nameErr: true, emailErr: false });
    } else if (user.email === "") {
      seterrorState({ nameErr: false, emailErr: true });
    } else if (!user.email.match(validation)) {
      seterrorState({ emailErr: true });
    } else {
      handleLogin()
    }
   
  };
  return (
    <>
      <div className="loginpage">
        <div className="titlelogo">
          <img className="textlogo" width={"100%"} src={logotitle} alt="logo" />
        </div>
        <div className="input">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              error={errorState.nameErr ? true : null}
              className="mb-4"
              id="outlined-error-helper-text"
              label="First Name"
              onChange={(e) => {
                handleError();
                setuser({ ...user, name: e.target.value });
              }}
              helperText={errorState.nameErr ? "First name is required" : null}
            />
            <TextField
              error={errorState.emailErr ? true : null}
              className="mb-4"
              id="outlined-error-helper-text"
              label="E-mail Address"
              onChange={(e) => {
                handleError();
                setuser({ ...user, email: e.target.value });
              }}
              helperText={errorState.emailErr ? "Email is required" : null}
            />
          </Box>
          <Button
            className="button"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 0, mb: 4 }}
            onClick={handleSubmit}
          >
            Continue
          </Button>

          <h6 className="MuiTypography-root MuiTypography-subtitle1 MuiTypography-colorTextSecondary MuiTypography-alignCenter">
            © 2021 MyWineGenie. All rights reserved.
            <a href="mailto:info@mywinegenie.com">Contact us</a>
          </h6>
        </div>
      </div>
    </>
  );
};

export default Loginform;
