import React from 'react';
import List from '@material-ui/core/List';
import TabResultItem from './TabResultItem';

export default function TabResult({ data }) {
  return (
    <div>
      <List className='px-4'>
        {data ?
          data.map((value, index) => (
            <TabResultItem key={index} name={value.name} image={value.image} price={value.price} index={index+1} sweetness={value.sweetness}
            body={value.body} tannin={value.tannin} acidity={value.acidity} citrus={value.citrus} oak={value.oak} spice={value.spice} fruit={value.fruit}
            floral={value.floral} herbal={value.herbal} herbaceous={value.herbaceous} />
           
           
             
            
          ))
          : null
        }
      </List >
    </div>
  );
}