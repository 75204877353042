import redirect from "./Redirect";
const handlebottle = (navigate, step) => {
  if (step ==="step3") {
    localStorage.removeItem("food");
    localStorage.removeItem("region");
    localStorage.removeItem("grape");
    localStorage.removeItem("ocassion");
    localStorage.removeItem("step3");
    redirect(navigate);
  } else if (step ==="step2") {
    localStorage.removeItem("food");
    localStorage.removeItem("region");
    localStorage.removeItem("grape");
    localStorage.removeItem("ocassion");
    localStorage.removeItem("step3");
    localStorage.removeItem("step2");
    redirect(navigate);
  }
  else if(step ==="step1"){
    localStorage.removeItem("food");
    localStorage.removeItem("region");
    localStorage.removeItem("grape");
    localStorage.removeItem("ocassion");
    localStorage.removeItem("step3");
    localStorage.removeItem("step2");
    localStorage.removeItem("step1");
    redirect(navigate);
  }
};

export default handlebottle;
