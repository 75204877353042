import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Title from "../titleview/Title";
import { useNavigate } from "react-router-dom";
import svgicon1 from "../../../asset/component/step2/bottle1step2.svg";
import svgicon2 from "../../../asset/component/step2/bottle2step2.svg";
import redirect from "../../Redirect";
import handleback from "../../handleback";
import handlebottle from "../../handleBottle";

const Step2 = () => {
  const navigate = useNavigate();
  useEffect(() => {
    redirect(navigate);
  }, []);

  const [checked, setChecked] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  });
  const type_of_wine = [
    {
      name: "Red",
      value: "wine_type_red",
    },
    {
      name: "White",
      value: "wine_type_white",
    },
    {
      name: "Rose",
      value: "wine_type_rose",
    },
    {
      name: "Sparkling",
      value: "wine_type_sparkling",
    },
    {
      name: "Fortified",
      value: "wine_type_fortified",
    },
  ];
  const step2_value_arr = [];
  const handleChange = (event, index) => {
    setChecked({ ...checked, [index]: event.target.checked });
  };
  const handlesubmitanswer = () => {
    if (sessionStorage.getItem("step2")) {
      localStorage.setItem("step2", sessionStorage.getItem("step2"));
        navigate("/step-3");
      } else {
        for (let [key, value] of Object.entries(checked)) {
          if (value === true) {
            step2_value_arr.push(type_of_wine[key].value);
          }
          if (value === true) {
            localStorage.setItem("step2", JSON.stringify(step2_value_arr));
            sessionStorage.setItem("step2", JSON.stringify(step2_value_arr));
            navigate("/step-3");
          }
        }
      }
    }
  const step2 = JSON.parse(sessionStorage.getItem("step2"));

  const handleSession = (e, value) => {
    if (step2.length === 1) {
      sessionStorage.removeItem("step2");
    } else {
      var index = step2.indexOf(value);
      if (index !== -1) {
        step2.splice(index, 1);
        sessionStorage.setItem("step2", JSON.stringify(step2));
      }
    }
  };

  return (
    <div className="position-relative">
      <Button
        className="button back-button mx-2"
        onClick={() => handleback(navigate, "step1")}
        variant="contained"
      >
        back
      </Button>
      <Box className="card-step-section">
        <Card className="card-step-wrapper" variant="outlined">
          <Title />
          <div className="iconstep2 icons-wrapper">
            <img
              className="iconimage"
              src={svgicon1}
              alt=""
              srcSet=""
              onClick={() => handlebottle(navigate, "step1")}
            />
            <img className="iconimage" src={svgicon2} alt="" srcSet="" />
          </div>

          <div className="question">
            <p className="questep2">
              What type of wine are you looking for? (check all that apply)
            </p>
          </div>
          <div className=" select-options">
            {type_of_wine.map((value, index) =>
              step2?.includes(value.value) ? (
                <FormControlLabel
                  key={index}
                  control={<Checkbox defaultChecked={true} color="primary" />}
                  onChange={(e) => handleChange(e, index)}
                  onClick={(e) => handleSession(e, value.value)}
                  value={value.value}
                  label={value.name}
                  name="wine_type"
                />
              ) : (
                <FormControlLabel
                  key={index}
                  control={<Checkbox color="primary" />}
                  onChange={(e) => handleChange(e, index)}
                  value={value.value}
                  label={value.name}
                  name="wine_type"
                />
              )
            )}
          </div>
          <Grid
            className="submit-answer-wrapper"
            item
            container
            justify="center"
            md={12}
          >
            <Button
              className="submit-answer"
              variant="contained"
              type="submit"
              onClick={(e) => handlesubmitanswer(e)}
              size="large"
              fullWidth
            >
              <span className="submit"> Submit Answer </span>
            </Button>
          </Grid>
        </Card>
      </Box>
    </div>
  );
};

export default Step2;
