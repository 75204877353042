import React, { useEffect, useState } from "react";
import Tabs from "./Tabs";
import "./result.css";
import URL from "../../../config/baseURL";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import redirect from "../../Redirect";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import handleReset from "../../handlereset";




const Result = () => {
  const navigate = useNavigate();
  const [resultType, setresultType] = useState("Products");
  const [value, setValue] = useState(0);
  const [products, setProducts] = useState([]);
  const [varietals, setVarietals] = useState([]);
  const [StatusCode, setStatusCode] = useState([]);

 



  useEffect(() => {
    redirect(navigate);
    axios
      .get(`${URL}/productsv2`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setProducts(res.data.products);
        setVarietals(res.data.varietals);
        setStatusCode(res.data.code);
      })
      .catch((err) => {
        if (err) {
          localStorage.clear();
          redirect(navigate);
        }
      });
  }, []);

  return (

    <>
    <div>
      {StatusCode !== 200 ? (
        <div className="loader custom-loader">
          <CircularProgress size={100} sx={{ color: "#720e37" }} />
        </div>
      ) : (
        <>
          <div className="result-top-btn"></div>
          <div className="text-center text-secondary">
            <h4>Here are your wine recommendations!</h4>
          </div>

          <div className="result-tabs text-center my-4">
            {resultType === "Products" ? (
              products.length === 0 ? (
                <Button
                  className="button result-btn"
                  onClick={() => handleReset(navigate)}
                >
                Trying something new
                </Button>
              ) : (
                <Tabs data={products} value={value} setValue={setValue} />
              )
            ) : varietals.length === 0 ? (
              <Button
              className="button result-btn"
              onClick={() => handleReset(navigate)}
            >
             Trying something new
            </Button>
            ) : (
              <Tabs data={varietals} value={value} setValue={setValue} />
            )}
          </div>
        </>
      )}
    </div>
    
    </>
  );
};

export default Result;
