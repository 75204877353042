import * as React from "react";
import {
  MultiSelectTree,
  getMultiSelectTreeValue,
} from "@progress/kendo-react-dropdowns";
import {
  processMultiSelectTreeData,
  expandedState,
} from "../step-3 region/tree-data";
import { Chip } from "@mui/material";




const Grapedropdown = ({ data, grape, setgrape, label }) => {
  const onFilterChange = (event) => setFilter(event.filter);
  const dataItemKey = "id";
  const checkField = "checkField";
  const checkIndeterminateField = "checkIndeterminateField";
  const subItemsField = "get-grape-varietals";
  const expandField ="expanded";
  const textField = "name";
  const fields = {
    dataItemKey,
    checkField,
    checkIndeterminateField,
    expandField,
    subItemsField,
    textField,
  };
  const [wine_arr, setWine_arr] = React.useState([]);
  const [value, setValue] = React.useState([]);
  const [expanded, setExpanded] = React.useState([data, [dataItemKey]]);
  const [filter, setFilter] = React.useState(null);
  const onChange = (event) => {

    setValue(getMultiSelectTreeValue(data, { ...fields, ...event, value}));
    setWine_arr([...wine_arr, event.items[0].name]);
  };


  const handleDelete = (e, index) => {
    grape.splice(index, 1);
    setgrape([...grape]);
  };

  const onExpandChange = React.useCallback(
    (event) => setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );
  const treeData = React.useMemo(
    () =>
      processMultiSelectTreeData(data, {
        expanded,
        value,
       
        filter,

        ...fields,
      }),
    [expanded, value, data, filter]
  );


  return (
    <>
      <MultiSelectTree
    className="border-dropdown" 
        style={{
          width: "300px",
          height: "35px",
        }}
        data={treeData}
        value={value}
        label={label}
        onChange={onChange}
        placeholder={label}
        textField={textField}
        dataItemKey={dataItemKey}
        checkField={checkField}
        checkIndeterminateField={checkIndeterminateField}
        expandField={expandField}
        subItemsField={subItemsField}
        onExpandChange={onExpandChange}
        filterable={true}
        onFilterChange={onFilterChange}
      />
      <div className="dropdown-tickets">
        {value.map((value, index) => {
          localStorage.setItem(label, JSON.stringify(wine_arr));
     
        
          return (
            <Chip
              onDelete={(e) => handleDelete(e, index)}
              className="m-2"
              key={index}
              label={value.name}
            />
          );
        })}
      </div>
    </>
  );
};

export default Grapedropdown;
