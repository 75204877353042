import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Card,
  FormControl,
  CircularProgress,
} from "@mui/material";
import Title from "../titleview/Title";
import { useNavigate } from "react-router-dom";
import svgicon1 from "../../../asset/component/step2/bottle1step2.svg";
import svgicon2 from "../../../asset/component/step2/bottle2step2.svg";
import svgicon3 from "../../../asset/component/step3/bottle3step3.svg";
import svgicon4 from "../../../asset/component/step4/bottle4step4.svg";
import redirect from "../../Redirect";
import axios from "axios";
import URL from "../../../config/baseURL";
import handleback from "../../handleback";
import handlebottle from "../../handleBottle";
import Fooddropdown from "./Fooddropdown";

const By_food = () => {
  const navigate = useNavigate();
  useEffect(() => {
    redirect(navigate);
  }, []);

  const [input, setinput] = useState([]);
  const [alldishes, setalldishes] = useState([]);
  const [mySet,setmySet] = useState([])
  const [popularDishes, setPopularDishes] = useState([]);
  




  useEffect(() => {
    axios
      .get(`${URL}/get-dishes`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setalldishes(res.data.dishes);
        setPopularDishes(res.data.popular);
      })
      .catch((err) => {
        if (err) {
          localStorage.clear()
          redirect(navigate)
        }
      })
  }, []);
  var newArr = []
  input.map(function(val, index){
    if(val.sub_dishes){
      newArr.push(val.name);
      val.sub_dishes.map((item)=>{
        newArr.push(item.name)
      })
    }
    else{
      newArr.push(val.name);
    }
})


  const handlesubmitanswer = (e) => {
    if (mySet.length !== 0 ||newArr.length !==0) {
        localStorage.setItem("food-pairing", JSON.stringify([...newArr].concat(mySet)));
      axios.post(
          `${URL}/submit-answersv2`,
          {
            price: JSON.parse(localStorage.getItem("step1")),
            wine_type: JSON.parse(localStorage.getItem("step2")),
            filter_by: localStorage.getItem("step3"),
            region: [],
            food: JSON.parse(localStorage.getItem("food-pairing")),
            occasion: "",
            grape_variety: [],
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          }
        )
        .then((res) => {
          if (res.data.code === 200) {
            navigate("/result");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    
  }
  else{

  }
  }
  return (
    <div className="position-relative">
        <Button className="button back-button mx-2" onClick={() => handleback(navigate,"step3")} variant="contained">
              back
            </Button>
      <Box className="card-step-section">
        <Card className="card-step-wrapper" variant="outlined">
          <Title />
          <div className="icons-wrapper">
            <img className="iconimage" src={svgicon1} alt="" srcSet=""  onClick={() => handlebottle(navigate,"step1")} />
            <img className="iconimage" src={svgicon2} alt="" srcSet=""  onClick={() => handlebottle(navigate,"step2")} />
            <img className="iconimage" src={svgicon3} alt="" srcSet=""  onClick={() => handlebottle(navigate,"step3")}  />
            <img className="iconimage" src={svgicon4} alt="" srcSet=""/>
          </div>
          <div className="question">
            Name your food pairing
            <p className="small-text">
              (please be as specific as possible [i.e. spicy chicken vindaloo])
            </p>
          </div>

          <div className="ticket-select-dropdown">
            <FormControl >
            <Fooddropdown  mySet={mySet} setmySet={setmySet}  data={alldishes} popular={input} setpopular={setinput}/>
            </FormControl>
          </div>
        
         
          <div className="popular drop-down-cards">
            {popularDishes.length === 0 ?
              <div className='loader small-custom-loader'>
                <CircularProgress sx={{ color: "#720e37" }} />
              </div>
              :
              popularDishes.map((value) => {
              
                return(

                mySet?.includes(value.name) || newArr.includes(value.name) ?
                <Button
                  className="col-5 submit-answer disabled"
                  variant="contained"
                >
                  {value.name}
                </Button>:
                 <Button
                 className="col-5 submit-answer"
                 variant="contained"
                 onClick={async () => {
                   setinput([...input, value])
                  
                 }}
                 size="large"
               >
                 {value.name}
               </Button>
              )})}
          </div>
          <Grid
            className="submit-answer-wrapper"
            item
            container
            justify="center"
            md={12}
          >
            <Button
              className="submit-answer"
              variant="contained"
              type="submit"
              onClick={(e) => handlesubmitanswer(e)}
              size="large"
              fullWidth
            >
              <span className="submit"> Submit Answer </span>
            </Button>
          </Grid>
        </Card>
      </Box>
    </div>
  );
};

export default By_food;
