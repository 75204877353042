
const redirect = (navigate) => {
  if (
    localStorage.getItem("occasion") ||
    localStorage.getItem("food-pairing") ||
    localStorage.getItem("grape_variety") ||
    localStorage.getItem("region")
  ) {
    navigate("/result");
  } else if (localStorage.getItem("step3")) {
    if (localStorage.getItem("step3") === "food") {
      navigate("/step-3/food");
    } else if (localStorage.getItem("step3") === "occasion") {
      navigate("/step-3/occasion");
    } else if (localStorage.getItem("step3") === "grape") {
      navigate("/step-3/grape");
    } else if (localStorage.getItem("step3") === "region") {
      navigate("/step-3/region");
    }
  } else if (localStorage.getItem("step2")) {
    navigate("/step-3");
  } else if (localStorage.getItem("step1")) {
    navigate("/step-2");
  } else if (localStorage.getItem("token")) {
    navigate("/step-1");
  } else {
    navigate("/");
  }
}

export default redirect;





