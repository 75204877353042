import * as React from "react";
import {
  MultiSelectTree,
  getMultiSelectTreeValue,
} from "@progress/kendo-react-dropdowns";
import {
  processMultiSelectTreeData,
  expandedState,
} from "../step-3 region/tree-data";
import { Chip } from "@mui/material";

const dataItemKey = "id";
const checkField = "checkField";
const checkIndeterminateField = "checkIndeterminateField";
const subItemsField = "sub_dishes";
const expandField = "expanded";
const textField = "name";
const fields = {
  dataItemKey,
  checkField,
  checkIndeterminateField,
  expandField,
  subItemsField,
  textField,
};

const Fooddropdown = ({mySet,setmySet, data,popular, setpopular }) => {
  const [value, setValue] = React.useState([]);
  const [expanded, setExpanded] = React.useState([data, [dataItemKey]]);
  const [filter, setFilter] = React.useState(null);

  if (value && value.length) {
    value.map((item) => {
      if(mySet.indexOf(item.name) === -1){
        setmySet([...mySet,item.name])
      }      
    });
}


  const onChange = (event) => {
   setmySet([])
    setValue(getMultiSelectTreeValue(data, { ...fields, ...event, value }));
  };


  const handleDelete = (e, index) => {
    popular.splice(index, 1);
    setpopular([...popular]);
    
  };
  const handleDeleteButton = (e, index) => {
    value.splice(index, 1);
    setValue([...value]);
    
    setmySet([])
    
    value.map((item,index) => {
      if(mySet.indexOf(item.name) === -1){
        setmySet([...mySet,item.name,mySet[index]-1]);
      
      }      
    })
  };

  const onExpandChange = React.useCallback(
    (event) =>{
     setExpanded(expandedState(event.item, dataItemKey, expanded));
    },
    [expanded]
  );
  const treeData = React.useMemo(
    () =>
      processMultiSelectTreeData(data, {
        expanded,
        value,
        filter,

        ...fields,
      }),
    [expanded, value, data, filter]
  );

  const onFilterChange = (event) => setFilter(event.filter);

  return (
    <>
      <MultiSelectTree 
    className="border-dropdown"
        style={{
          width: "300px",
          height: "35px",
        }}
        data={treeData}
        value={value}
        onChange={onChange}
        placeholder="select"
        textField={textField}
        dataItemKey={dataItemKey}
        checkField={checkField}
        checkIndeterminateField={checkIndeterminateField}
        expandField={expandField}
        subItemsField={subItemsField}
        onExpandChange={onExpandChange}
        filterable={true}
        onFilterChange={onFilterChange}
      />
      <div className="dropdown-tickets">
      {value.map((value, index) => {
          return (
            <Chip
              onDelete={(e) => handleDeleteButton(e, index)}
              className="m-2"
              key={index}
              label={value.name}
            />
          );
        })}
        {popular.map((value, index) => (
                <Chip
                  onDelete={(e) => handleDelete(e, index)}
                  className="m-2"
                  key={index}
                  label={value.name}
                />
            
        ))}
      </div>
    </>
  );
};

export default Fooddropdown;
