import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
} from "@mui/material";
import React, { useEffect } from "react";
import Title from "../titleview/Title";
import { useNavigate } from "react-router-dom";
import svgicon1 from "../../../asset/component/step2/bottle1step2.svg";
import svgicon2 from "../../../asset/component/step2/bottle2step2.svg";
import svgicon3 from "../../../asset/component/step3/bottle3step3.svg";
import svgicon4 from "../../../asset/component/step4/bottle4step4.svg";

import redirect from "../../Redirect";
import axios from "axios";
import URL from "../../../config/baseURL";
import handleback from "../../handleback";
import handlebottle from "../../handleBottle";
import Grapedropdown from "./DropDownWithChips";

const By_grape = () => {
  const [checked, setChecked] = React.useState("");
  const [grapdata, setgrapdata] = React.useState([]);
  const handleChange = (event) => {
    setChecked(event.target.value);
  };
  const navigate = useNavigate();
  useEffect(() => {
    redirect(navigate)
  }, [])

  useEffect(() => {
    axios.get(`${URL}/get-grape-varietals`, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } })
      .then((res) => {
          setgrapdata(res.data.grape_varietals)
      })
      .catch((err)=>{
        if(err){
            localStorage.clear()
            redirect(navigate)
        }
    })
  }, [])




  const handlesubmitanswer = () => {
    const grapeData = []
    JSON.parse(localStorage.getItem("step2"))?.map((value) => {
      JSON.parse(localStorage.getItem(value.slice(10,)[0].toUpperCase() + value.slice(11,)))?.map((value) => {
        grapeData.push(value)
      })
      localStorage.removeItem(value.slice(10,)[0].toUpperCase() + value.slice(11,))
    })
    
    localStorage.setItem("grape_variety", JSON.stringify(grapeData))
    if(localStorage.getItem("grape_variety")!=='[]'){
      axios
        .post(
          `${URL}/submit-answersv2`,
          {
            "price": JSON.parse(localStorage.getItem("step1")),
            "wine_type": JSON.parse(localStorage.getItem("step2")),
            "filter_by": localStorage.getItem("step3"),
            "food": [],
            "region": [],
            "occasion": "",
            "grape_variety": JSON.parse(localStorage.getItem("grape_variety")),
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          }
        )
        .then((res) => {
          if (res.data.code === 200) {
            localStorage.removeItem("Red") 
            localStorage.removeItem("White")
            localStorage.removeItem("Rose")
            localStorage.removeItem("Sparkling")
            localStorage.removeItem("Fortified")
            navigate("/result");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div className="position-relative">
        <Button className="button back-button mx-2" onClick={() => handleback(navigate,"step3")} variant="contained">
              back
            </Button>
      <Box className="card-step-section">
        <Card className="card-step-wrapper" variant="outlined">
          <Title />
          <div className="icons-wrapper">
          <img className="iconimage" src={svgicon1} alt="" srcSet=""  onClick={() => handlebottle(navigate,"step1")} />
            <img className="iconimage" src={svgicon2} alt="" srcSet=""  onClick={() => handlebottle(navigate,"step2")} />
            <img className="iconimage" src={svgicon3} alt="" srcSet=""  onClick={() => handlebottle(navigate,"step3")}  />
            <img className="iconimage" src={svgicon4} alt="" srcSet="" />
          </div>

          <div className="question">
            <p className="questep2">
              Name popular grape varietals you’re looking to discover from
            </p>
          </div>
          {
            grapdata.length === 0 ?
              <div className='loader small-custom-loader'>
                <CircularProgress sx={{ color: "#720e37" }} />
              </div>
              :
              <div className="text-center">
                {
                  JSON.parse(localStorage.getItem("step2")).map((wine_type) => {
                    var grap = grapdata.filter((value) => (value.type === wine_type.slice(10,)[0].toUpperCase() + wine_type.slice(11,)))
                    var grapeData = []
                    grap.map((value, index) => {
                      grapeData.push(value.name)
                    })
                  
                    return (
                      <Grapedropdown data={grap} grape={grapeData} setgrape={setgrapdata} label={wine_type.slice(10,)[0].toUpperCase() + wine_type.slice(11,)} />
                    )
                  })
                }
              </div>
          }
          <Grid
            className="submit-answer-wrapper"
            item
            container
            justify="center"
            md={12}
          >
            <Button
              className="submit-answer"
              variant="contained"
              type="submit"
              onClick={(e) => handlesubmitanswer(e)}
              size="large"
              fullWidth
            >
              <span className="submit"> Submit Answer </span>
            </Button>
          </Grid>
        </Card>
      </Box>
    </div>
  );
};

export default By_grape;
