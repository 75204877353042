import { ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";

import { Button, Card, Grid, Slider, Typography } from "@mui/material";
import React from "react";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";


function TabResultItem(props) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function valuetext(value) {
    return `${value}°C`;
  }
  return (
    <>
      <Card onClick={handleClickOpen} className="my-4">
        <ListItem>
          <Typography className="mx-4" variant="body2" color="initial">
            {props.index}
          </Typography>
          <ListItemAvatar>
            <img
              className="p-3"
              src={`https://api.mywinegenie.com/${props.image}`}
              height={100}
              width={80}
              alt=""
            />
          </ListItemAvatar>
          <ListItemText primary={props.name} secondary={`$${props.price}`} />
        </ListItem>
      </Card>
      <div >
        <Dialog
        className="box"
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <div 
            style={{ marginTop: "10px", marginRight: "10px" }}
            autoFocus
            onClick={handleClose}
          >
            <CloseIcon style={{ float: "right" }} />
          </div>
          <DialogContent className="main-dailog">
            <DialogContentText className="full-dailog" >
              <div className="img-wrapper">
              <img
                className=""
                src={`https://api.mywinegenie.com/${props.image}`}
                height={300}
                width={200}
                alt=""
              />
              </div>
              <DialogTitle id="responsive-dialog-title" className="popup-body">
               <div className="pop-title"> {props.name} </div>
                <div className="pricemodel">{`$${props.price}`}</div>
                <Typography className="subcard-title" variant="body2" color="initial">
                {"Tasting Notes"}
              </Typography>
              <div className="allrange">
              <div className="winerange col-sm-12 mt-4">
              <Grid className="col-6 d-grid" >
                Sweetness
                <Slider className="slidercolor"
                style={{width:200}}
                disabled
                  max={10}
                  min={0}
                  aria-label="sweetness"
                  defaultValue={props.sweetness}
                  getAriaValueText={valuetext}
                  color="secondary"
                />
              </Grid>
              <Grid className="winerangetwo d-grid" variant="body2">
              Body
                <Slider className="slidercolor"
                style={{width:200}}
                disabled
                  max={10}
                  min={0}
                  aria-label="body"
                  defaultValue={props.body}
                  getAriaValueText={valuetext}
                  color="secondary"
                />
              </Grid>
              </div>
              <div className="winerange mt-4">
              <Grid className="col-6 d-grid" variant="body2">
              Tannin
                <Slider className="slidercolor"
                style={{width:200}}
                disabled
                  max={10}
                  min={0}
                  aria-label="tannin"
                  defaultValue={props.tannin}
                  getAriaValueText={valuetext}
                  color="secondary"
                />
              </Grid>
              <Grid className="winerangetwo d-grid" variant="body2">
              Acidity
                <Slider className="slidercolor"
                style={{width:200}}
                disabled
                  max={10}
                  min={0}
                  aria-label="acidity"
                  defaultValue={props.acidity}
                  getAriaValueText={valuetext}
                  color="secondary"
                />
              </Grid>
              </div>
              <div className="winerange mt-4">
              <Grid className="col-6 d-grid" variant="body2">
              Citrus
                <Slider className="slidercolor"
                style={{width:200}}
                disabled
                  max={10}
                  min={0}
                  aria-label="citrus"
                  defaultValue={props.citrus}
                  getAriaValueText={valuetext}
                  color="secondary"
                />
              </Grid>
              <Grid className="winerangetwo d-grid" variant="body2">
              Herbal
                <Slider className="slidercolor"
                style={{width:200}}
                disabled
                  max={10}
                  min={0}
                  aria-label="herbal"
                  defaultValue={props.herbal}
                  getAriaValueText={valuetext}
                  color="secondary"
                />
              </Grid>
              </div>
              <div className="winerange mt-4">
              <Grid className="col-6 d-grid" variant="body2">
              Oak
                <Slider className="slidercolor"
                style={{width:200}}
                disabled
                  max={10}
                  min={0}
                  aria-label="oak"
                  defaultValue={props.oak}
                  getAriaValueText={valuetext}
                  color="secondary"
                />
              </Grid>
              <Grid className=" winerangetwo d-grid" variant="body2">
              Spice
                <Slider className="slidercolor"
                style={{width:200}}
                disabled
                  max={10}
                  min={0}
                  aria-label="spice"
                  defaultValue={props.spice}
                  getAriaValueText={valuetext}
                  color="secondary"
                />
              </Grid>
              </div>
              <div className="winerange mt-4">
              <Grid className="col-6 d-grid" variant="body2">
              Fruit
                <Slider className="slidercolor"
                style={{width:200}}
                disabled
                  max={10}
                  min={0}
                  aria-label="fruit"
                  defaultValue={props.fruit}
                  getAriaValueText={valuetext}
                  color="secondary"
                />
              </Grid>
              <Grid className="winerangetwo d-grid" variant="body2">
              Floral
                <Slider className="slidercolor"
                style={{width:200}}
                disabled
                  max={10}
                  min={0}
                  aria-label="floral"
                  defaultValue={props.floral}
                  getAriaValueText={valuetext}
                  color="secondary"
                />
              </Grid>
              </div>
              </div>
              </DialogTitle>
            </DialogContentText>
           
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default TabResultItem;
