import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Card,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import Title from "../titleview/Title";
import svgicon from "../../../asset/component/step1/bottle.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import redirect from "../../Redirect";
import handleback from "../../handleback";

const Step1 = () => {
  const navigate = useNavigate();
  useEffect(() => {
    redirect(navigate);
  }, []);
  const step1 = sessionStorage.getItem("step1");

  const [checked, setChecked] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  });
  const budget = [
    {
      name: "<$25",
      value: 25,
    },
    {
      name: "$25-$50",
      value: 50,
    },
    {
      name: "$50+",
      value: 51,
    },
  ];
  const step1_value_arr = [];
  const handleChange = (event, index,key) => {
    setChecked({ ...checked, [index]: event.target.checked });
  };
  const handlesubmitanswer = (e) => {
    if(step1){
      localStorage.setItem("step1", sessionStorage.getItem("step1"));
      navigate("/step-2");
    }
    else{
      for (let [key, value] of Object.entries(checked)) {
        if (value !== false) {
          step1_value_arr.push(budget[key].value);
        }
      }
   
      if (step1_value_arr.length !== 0) {
        localStorage.setItem("step1", Math.max(...step1_value_arr));
        sessionStorage.setItem("step1", Math.max(...step1_value_arr));
        navigate("/step-2");
      }
    }
  };
  return (
    <div>
    
      <Box className="card-step-section">
        <Card className="card-step-wrapper" variant="outlined">
          <Title />
          <div className="icon icons-wrapper">
            <img className="iconimage" src={svgicon} alt="" srcSet="" />
          </div>
          <div className="question ">
            <p className="que">
              {" "}
              How much do you want to spend on a bottle of wine? (check all that
              apply)
            </p>
          </div>
          <div className=" select-options">
            {budget.map((value, index) =>
              value.value === parseInt(step1) ? (
                <FormControlLabel
                  key={index}
                  control={<Checkbox defaultChecked={true} color="primary" />}
                  onChange={(e) => handleChange(e, index)}
                  onClick={()=>(sessionStorage.removeItem("step1"))}
                  value={value.value}
                  label={value.name}
                  name="wine_price"
                />
              ) : (
                <FormControlLabel
                  key={index}
                  control={<Checkbox color="primary" />}
                  onChange={(e) => handleChange(e, index)}
                  value={value.value}
                  label={value.name}
                  name="wine_price"
                />
              )
            )}
          </div>
          <Grid
            className="submit-answer-wrapper"
            item
            container
            justify="center"
            md={12}
          >
            <Button
              className="submit-answer"
              variant="contained"
              type="submit"
              onClick={(e) => handlesubmitanswer(e)}
              size="large"
              fullWidth
            >
              <span className="submit"> Submit Answer </span>
            </Button>
          </Grid>
        </Card>
      </Box>
    </div>
  );
};

export default Step1;
