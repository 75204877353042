import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabResult from './TabResult';

export default function Tabs({data,value,setValue}) {
 
  const handleChange = (e,newValue) => {
    setValue(newValue);
  };
  const tabs = [...Object.keys(data)]
  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box className='tabs'>
          <TabList className='tab-list' onChange={handleChange} aria-label="lab API tabs example">
            {
              tabs.map((value, index) => (
                <Tab key={index} className='px-4 tab-name' label={value} value={index} />
              ))
            }
          </TabList>
        </Box>
        {
          tabs.map((tabData, index) => (
            <TabPanel value={index}>
              <TabResult  data={data[tabData]}/>
            </TabPanel>
      ))
        }
    </TabContext>
    </Box >
  );
}
