import React from "react";
import logo from "../asset/image/headerlogo.png"
import "../component/header.css";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import redirect from "./Redirect";
const Header = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };
  const handleReset = () => {
    localStorage.removeItem("step3")
    localStorage.removeItem("step1")
    localStorage.removeItem("step2")
    localStorage.removeItem("food-pairing")
    localStorage.removeItem("region")
    localStorage.removeItem("grape_variety")
    localStorage.removeItem("occasion")
    localStorage.removeItem("red") 
    localStorage.removeItem("white")
    localStorage.removeItem("rose")
    localStorage.removeItem("sparkling")
    localStorage.removeItem("Fortified")
    sessionStorage.removeItem("step3")
    sessionStorage.removeItem("step1")
    sessionStorage.removeItem("step2")
    sessionStorage.removeItem("food-pairing")
    sessionStorage.removeItem("region")
    sessionStorage.removeItem("grape_variety")
    sessionStorage.removeItem("occasion")
    sessionStorage.removeItem("red") 
    sessionStorage.removeItem("white")
    sessionStorage.removeItem("rose")
    sessionStorage.removeItem("sparkling")
    sessionStorage.removeItem("Fortified")
    redirect(navigate)
  };
  return (
    <div className="border-header">
      <div className="logo d-flex">
        <img src={logo} alt="logo" onClick={(e) => (redirect(navigate))} />
        {localStorage.getItem("token") ? (
          <div className="header-buttons text-end">
            <Button className="button mx-2" onClick={handleReset} variant="contained">
              reset
            </Button>
            <Button
              className="button"
              variant="contained"
              onClick={handleLogout}
            >
              log out
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
